<template>
  <div class="contain">
      <!-- 复制eventPolling.vue 修改的话同步修改 -->
      <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :model="search"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="64px">
          <el-date-picker
            @change="searchFun"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
   
        <el-form-item label="事件类型"  style="margin-left: 16px;">
          <el-cascader
            size="mini"
            style="width: 100%;"
            :options="eventTypeList"
            v-model="eventTypeIdList"
            :props="{  label:'name' , value:'id' ,multiple: true }"
            separator='>'
            clearable
            collapse-tags
            @change="deformation">
          </el-cascader>
        </el-form-item>
        
        <el-form-item label="有无异常项" prop="equipmentStatus" style="margin-left: 28px;">
          <el-select
            clearable
            size="mini"
            style="width:170px;"
            v-model="search.isHav"
            placeholder="请选择"
            @change="inquire"
          >
            <el-option
              v-for="item in isHavArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" style="margin-left: 18px;" label-width="64px">
          <el-select
            clearable
            size="mini"
            style="width:170px;"
            v-model="search.userId"
            placeholder="请选择巡线员"
            @change="inquire"
          >
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="equipmentStatus" style="margin-left: 8px;" label-width="64px">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="search.state"
            placeholder="请选择状态"
            @change="inquire"
          >
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus"  label-width="64px">
          <el-input
          size="mini"
            placeholder="请输入任务编号/地址"
            v-model="search.searchText"
            style="width:250px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          type="primary"
          size="mini"
          @click="searchFun"
          style="margin-left:20px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          type="warning"
          @click="reset"
          >重置</el-button
        >
        <!-- <el-button
          size="mini"
          class="reset"
          type="primary"
          @click="derive"
          v-if="$anthButtons.getButtonAuth('eventEquipment')" 
          >导出</el-button
        > -->
        </el-form-item>
      </el-form>
    </div>
    <div class="modalityCenter">
    
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      height="calc(100vh - 238px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
            <el-table-column
          type="index"
          label="序号"
          align="left"
          width="50"
          >
          </el-table-column>
              <el-table-column prop="taskCode" label="任务编号" align="left" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="eventCodeIdConfirmName" label="事件类型" align="left" show-overflow-tooltip>
                
              </el-table-column>
              <el-table-column prop="eventAddressConfirm" label="地址" align="left" show-overflow-tooltip>
               
              </el-table-column>
              <el-table-column prop="isHav" label="有无异常项" align="left" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.isHav>0" style="color:#E83434">有</span>
                    <span v-else>无</span>
                </template>
              </el-table-column>
              <el-table-column prop="executorPeopleName" label="巡线员" align="left" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="createTime" label="巡检时间" width="170" align="left" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="duration" label="巡检时长" width="170" align="left" show-overflow-tooltip>
                 <template slot-scope="scope">
                <!-- {{(scope.row.duration/60).toFixed(2)}} <span>分钟</span> -->
                {{parseInt(scope.row.duration/60/60%24)}} <span>小时</span>
                {{parseInt(scope.row.duration/60%60)}} <span>分钟</span>
                {{parseInt(scope.row.duration%60)}} <span>秒</span>
                </template>
              </el-table-column>
              
              <el-table-column prop="itemName" label="关联事件(已处理数/总数)" align="left" show-overflow-tooltip>
                 <template slot-scope="scope">
                  <div style="color: #1082ff; cursor: pointer" v-if="scope.row.processedSum > 0" @click="appear(scope.row)">{{scope.row.processed}}/{{scope.row.processedSum}}</div>
                  <div v-else>{{scope.row.processed}}/{{scope.row.processedSum}}</div>
                 <!-- {{scope.row.processed}}/{{scope.row.processedSum}} -->
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="状态" width="80" align="left" show-overflow-tooltip>
                 <template slot-scope="{ row }">
                    <!-- <el-tag v-if="row.trackStatus == '0'" size="mini" style="color: #E83434;border: 1px solid #F5A7A7;background: #FFF4EB;">
                        待处理
                    </el-tag> 
                    <el-tag v-else type='success' size="mini">
                       已处理
                    </el-tag>   -->
                    <div v-if="row.processedSum == '0'" >
                       --
                    </div> 
                    <el-tag v-else-if="row.processedSum <= row.processed"  type='success' size="mini">
                       已处理
                    </el-tag> 
                    <el-tag v-else size="mini" style="color: #E83434;border: 1px solid #F5A7A7;background: #FFF4EB;">
                        待处理
                    </el-tag> 
                 </template>
              </el-table-column>
              <el-table-column prop="itemName" label="操作" align="center" width="100px">
                <template slot-scope="{ row }">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="IsOpenFun(row)"
                        v-if="$anthButtons.getButtonAuth('lookIncident')" 
                        >查看</el-button>
                    </template>
              </el-table-column>
    
    </el-table>

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[ 10,30, 50, 100]"
        :page-size="search.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    </div>
   
  </div>
</template>

<script>
import {
  dicByType
} from "@/RequestPort/maintenance";
import { getUserList} from "@/RequestPort/maintenance/task";
import { dataPage,excelEventData} from "@/RequestPort/checkTask/task";
import { exportMethod } from '../../common/js/exportExcel'

export default {
  name: "inspePlan",
  data() {
    return {
      excutorDownArray: [],
      networkList:[],
      
      // 状态
      stateList:[
        { label: "待处理", value: 0 },
        { label: "已处理", value: 1 },
      ],
      // monthArray: [],
      DataPicker: [],
      search: {
        current: 1,
        size: 100,
      },
      // status: "",
      // 列表数据
      tableData: [],
      // searchText: "",
      // 总条数
      total: 0,
      choseArr: [],
     
      form: {
        executorPeopleId: "",
        networkId: "",
      },
      networkIdArray:[],
      isHavArr:[{label:'有',value:1},{label:'无',value:2}],
      eventTypeList:[],
      eventTypeIdList:[]
    };
  },
  mounted() {
    if(this.$route.query.id) {
      this.search.taskId = this.$route.query.id
    }
    this.getOption();
    this.getList();
  },
  methods: {
    /**
     * 导出
     */
    derive(){
      let data = {...this.search}
      excelEventData(data).then(res => {
        exportMethod(res, '事件巡检')
      })
    },
    /**
     * 上报事件
     */
    appear(row){
      // row:JSON.stringify(row)
      // this.$router.push({ path: '/home/incident', query: {id:row.eventId } })
      let chakan = this.$router.resolve({
					path: '/home/incident',      //跳转目标窗口的地址
					query:{id:row.eventId }
				})
				window.open(chakan.href);
    },
     deformation(){
      let text = []
      this.eventTypeIdList.forEach(el => {
        text.push(el[1])
      })
      this.search.eventType = text.join(',')
      // this.search.eqType = this.eventTypeIdList[1]
      // this.getList()
    },
    // 查看
    IsOpenFun(row){
        // this.$router.push({ path: '/home/eventPollingDesc', query: { id: row.tplId,row:JSON.stringify(row)} })
        let chakan = this.$router.resolve({
					path: '/home/eventPollingDesc',      //跳转目标窗口的地址
					query: { id: row.tplId,row:JSON.stringify(row)}
				})
				window.open(chakan.href);
    },
    
    getOption(){
  
    /**
     * 获取事件类型
     */
      dicByType({parentCode:'GAS_PATROL_EVENT',level:2}).then(res=>{
        if(res.code===200){
          for(let i=0;i<res.data.length;i++){
					let el=res.data[i]
					if(!el.children){
						res.data.splice(i,1)
						i=i-1
					}
				}
          this.eventTypeList = res.data
        }
      })
      getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
    /**
     * 触发下拉进行查询
     */
    inquire(){
      this.searchFun()
    },
    
    
    /**
     * 查询事件
     */
    searchFun() {
      this.search.current = 1;
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.search.startDate = this.DataPicker[0];
        this.search.endDate = this.DataPicker[1];
      } else {
        this.search.startDate = null;
        this.search.endDate = null;
      }
      this.getList();
    },
    /**
     * 重置
     */
    reset() {
      this.search = {
        current: 1,
        planName: "",
        size: 100,
      };
      this.DataPicker = [];
      this.eventTypeIdList = [];
      this.getList();
    },
   
  

    /**
     * 查询列表数据
     */
    getList() {
      dataPage(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handleSizeChange(e) {
      this.search.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getList();
    },
    handleSelectionChange(e) {
      this.choseArr = e;
    },
  },
};
</script>

<style lang="less" scoped>
.contain{
  background-color:transparent;
  padding: 0;
}

// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 10px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
 
}

// 搜索
.searchForm{
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  .el-form-item{
    margin-bottom: 0!important;
  }
}
// 区域鼠标移上事件
.examine{
 cursor: pointer;
}
// 分页按钮
.el-pagination{
      margin: 15px 0 5px;
}
.view-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.view-table th {
  // background-color: #f5faff;
  font-weight: 400;
  text-align: left;
  width: 180px;
}
.view-table td,
.view-table th {
  border: 1px solid #d1e8fe;
  line-height: 40px;
  padding: 0 15px;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  .left {
    width: 30%;
    margin: 10px 0;
  }
  .right {
    width: 35%;
  }
}
.el-input-group,
.el-input {
  width: initial;
}
.reset {
  margin-left: 10px;
}
.block {
  text-align: right;
}
</style>
